import { render, staticRenderFns } from "./CourseTime.vue?vue&type=template&id=284ff730"
import script from "./CourseTime.vue?vue&type=script&lang=js"
export * from "./CourseTime.vue?vue&type=script&lang=js"
import style0 from "./CourseTime.vue?vue&type=style&index=0&id=284ff730&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports